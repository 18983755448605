import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import ResponsiveSpacing from "../components/responsive-spacing"
import ConnectedFlavors from "../components/flavors/grid"
import Inner from "../components/inner"
import SEO from "../components/seo"
import HalfHero from "../components/half-hero"
import Button from "../components/button"
import Spacer from "../components/spacer"
import Center from "../components/center"
import BottomColorNav from "../components/bottom-color-nav"
import Heading from "../components/heading"
import { useStaticQuery, graphql } from "gatsby"
import TitleCase from "../utils/titlecase"

const BannerStyled = styled.div`
  background-color: ${(p) => p.theme.BGBlue};
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: column;
  position: relative;
  z-index: 0;
  @media (max-width: 768px) {
    padding-bottom: 30px;
  }
`

const Banner = ({ children }) => {
  return <BannerStyled>{children}</BannerStyled>
}

const AvailablePage = () => {
  const data = useStaticQuery(pageQuery)
  const page = data.page.edges[0].node
  const available = page.acf.available_flavors.map((a) => a.wordpress_id)
  const flavors = data.flavors.edges
    .map((e) => e.node)
    .filter((f) => {
      if (f.acf.flavor.not_for_sale) return false
      return available.includes(f.wordpress_id)
    })
  const fields = page.acf

  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields

  return (
    <Layout>
      <SEO
        title={search_title || TitleCase(fields.opening.headline)}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <HalfHero fields={fields} color={"DarkBlue"} />
      <ResponsiveSpacing>
        <Inner maxWidth="1400px">
          <Heading margin="50px 0">
            <h2>{fields.opening.headline}</h2>
            <h3>{fields.opening.sub_heading}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: fields.opening.statement,
              }}
            />
          </Heading>
          <ConnectedFlavors
            flavors={flavors}
            page={page}
            noButton={true}
            noPrice={true}
          />
        </Inner>
      </ResponsiveSpacing>
      <Spacer height={"80px"} />
      <Banner>
        <ResponsiveSpacing>
          <Heading>
            <h2>{fields.bottom_cta.headline}</h2>
            <h3>{fields.bottom_cta.sub_heading}</h3>
          </Heading>
          <Center margin="15px auto">
            <Button to={fields.bottom_cta.button_link}>
              {fields.bottom_cta.button_label}
            </Button>
          </Center>
        </ResponsiveSpacing>
      </Banner>
      <BottomColorNav />
    </Layout>
  )
}

const pageQuery = graphql`
  query {
    flavors: allWordpressWpFlavors {
      edges {
        node {
          title
          wordpress_id
          acf {
            flavor {
              name
              name_with_html
              price
              not_for_sale
              tags
              color_1
              color_2
              promotion
              carton_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              inside_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            nutrition_feature {
              headline
              summary
              button_label
              button_link
              wave_color
              color_override
              image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    page: allWordpressPage(
      filter: { slug: { eq: "available-in-canada-page" } }
    ) {
      edges {
        node {
          acf {
            hero_area {
              headline
              headline_color
              wave_color
              background {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            opening {
              headline
              sub_heading
              statement
            }
            bottom_cta {
              headline
              sub_heading
              button_label
              button_link
            }
            available_flavors {
              wordpress_id
            }
            search_title
            search_description
            share_headline
            share_comment
            share_image {
              source_url
            }
          }
        }
      }
    }
  }
`

export default AvailablePage
